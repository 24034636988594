import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"

const EventPage = ({ data }) => {
  const { eventQuery } = data
  const event = eventQuery["frontmatter"]

  const imageStyle = { borderRadius: "10px", maxWidth: 300 }

  return (
    <Layout>
      <div className="text-xl mb-5">Event: {event.title}</div>
      <div className="text-base mb-5">Date: {event.date}</div>
      <div className="text-base mb-7">Description: {event.description}</div>
      <Img style={imageStyle} fluid={event.image.childImageSharp.fluid} width="500" height="auto" />
    </Layout>
  )
}

export default EventPage

// String! implies non-nullable
// We are guaranteed an id because once was assigned on page creation
export const pageQuery = graphql`
  query EventByID($id: String!) {
    eventQuery: markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        image {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`
